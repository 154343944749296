.contact-form form input{
    color: #fff;
}
.contact-form form textarea{
    color: #fff;
}

.exp-subscribe-box form input{
    /* color: #fff; */
}
.invalid-feedback{
    margin-top: 0;
    /* margin-bottom: 14px; */
}

.input_label{
    margin-top: 14px;
}
.contact_arrow{
    padding-top: 15px;
    padding-right: 30px;
}

.invalid-message{
    display: none;
    width: 100%;
    /* margin-top: 0.25rem; */
    font-size: .875em;
    color: #dc3545;
}

button.sbmt-form{
    margin: 14px 0px 10px 0px;
}