.location-block {
  position: relative;
  /* margin-right: 1rem; */
}

.location-block i {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0%, -50%);
}

.location-block {
  background-color: #000000;
  border: none;
  border-bottom: 1px solid #349b53;
  padding: 8px 0px 8px 15px;
  color: #b1b1b1;
  font-weight: 300;
  /* border-radius: 20px; */
}

.location-block .select_box {
  min-width: 12rem;
}

.location-block .selectbox_content {
  width: 100%;
  min-width: auto;
  margin-top: 14px;
  left: 0rem;
  min-width: max-content;
}


.location-block .selectbox_btn {
  background: black;
  padding: 0 0 0 10px;
}

.location-block .selected_title {
  color: #ffffff;
}

.form-select {
  border-radius: 0;
}

.home_select_option {
  padding: 8px 14px;
  /* word-break: break-all; */
}

.home_select_option:hover {
  background: #c4e4cd;
}
