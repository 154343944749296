


.adpurchage_btn_active:hover {
  color: white !important;
}

.adpurchage_standard_active:hover{
    background-color: #349c52 !important;
    color: white !important;
}